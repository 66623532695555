import React, {Component} from 'react';
import './About.css';
import ReactGA from 'react-ga';
import AgreementBabyMonitor from "./AgreementBabyMonitor";
import PrivacyBabyMonitor from "./PrivacyBabyMonitor";

let _this;

class BabyMonitor extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {
        ReactGA.pageview('BabyTube');
    }


    render() {
        return <div>
            <p className="container">
                <br/>
                <div align="right">
                    <div className="fast_nav container">
                        <ul>
                            <li className="first">
                                <a href="#Products" role="button"
                                   onClick={() => _this.props.navigate('Products')}>Home</a>
                            </li>
                            <li>
                                <a href="#PrivacyBabyMonitor" role="button"
                                   onClick={() => _this.props.navigate('PrivacyBabyMonitor')}>Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#AgreementBabyMonitor" role="button"
                                   onClick={() => _this.props.navigate('AgreementBabyMonitor')}>User Agreement</a>
                            </li>
                            <li>
                                <a href="mailto:support@answersolutions.net">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card" id="shell4">
                    <div id="content">
                        <div>
                            <img style={{borderRadius: '10%'}} src="/app_baby_watcher.png" align="left" hspace="10"
                                 vspace="10" alt="app icon"
                                 width="128px"/>
                            <br/>
                            <p align="left"><h2>Baby Monitor And Camera WiFi</h2></p>
                            <p align="left"><h3>Look after what is important to you! One phone is a monitor, 2nd is a
                                camera!</h3></p>
                            <p align="left">
                                <body>
                                Baby Watcher App is a Wi-Fi Camera and Monitor that lets you use any two phones to Keep
                                an
                                eye on what is important to you.
                                You need two devices and the App installed on each device.
                                One phone is a monitor, and the second is a remotely controlled camera.
                                </body>
                            </p>
                        </div>
                        <div className="row">
                            <div className="column">
                                <img src="/BabyWatcher.playstore.banner.png" alt="screenshot 1" width="100%"/>
                            </div>
                        </div>
                    </div>
                    <div align="right">
                        <a href="https://apps.apple.com/us/app/baby-watcher-eye-wi-fi/id930583309"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.ansol.selfiepro" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            2014-2023 © AnswerSolutions LLC
                        </li>
                        <li>
                            <a href="#Products" role="button" onClick={() => _this.props.navigate('Products')}>Home</a>
                        </li>
                        <li>
                            <a href="#PrivacyBabyMonitor" role="button"
                               onClick={() => _this.props.navigate('PrivacyBabyMonitor')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#AgreementBabyMonitor" role="button"
                               onClick={() => _this.props.navigate('AgreementBabyMonitor')}>User Agreement</a>
                        </li>
                        <li>
                            <a href="#support" role="button"
                               onClick={() => _this.props.navigate('Support')}>Support</a>
                        </li>
                    </ul>
                </div>
            </p>
        </div>
    }
}

export default BabyMonitor;