import React, {Component} from 'react';
import './About.css';
import ReactGA from 'react-ga';

let _this;

class BabyTube extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {
        ReactGA.pageview('BabyTube');
    }


    render() {
        return <div>
            <p className="container">
                <br/>
                <div align="right">
                    <div className="fast_nav container">
                        <ul>
                            <li className="first">
                                <a href="#Products" role="button" onClick={() => _this.props.navigate('Products')}>Home</a>
                            </li>
                            <li>
                                <a href="#PrivacyBabyTube" role="button"
                                   onClick={() => _this.props.navigate('PrivacyBabyTube')}>Privacy
                                    Policy</a>
                            </li>
                            <li>
                                <a href="mailto:support@answersolutions.net">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
                <div className="card" id="shell4">
                    <div id="content">
                        <div>
                            <img src="/babytube.png" align="left" hspace="10" vspace="10" alt="app icon"
                                 width="128px"/>
                            <p align="left"><h2>BabyTube Internet Video player</h2></p>
                            <p align="left"><h3>Made for Parents & Babies</h3></p>
                            <p align="left">
                                <body>
                                With BabyTube, you can relax while your child watches only the content you chose and
                                approved.
                                No Account or registration is needed!
                                </body>
                            </p>
                            <p><p>
                                Watching online videos can be great, but when your baby has access to all the content at
                                once, it may expose him to unwanted or inappropriate videos.
                            </p><p>
                                Suggested videos involving violence, dirty language, or playing with the search tool are
                                a common problem that we're here to fix!
                            </p><p>
                                With <b>BabyTube</b>, you can relax while your baby watches only the content you chose
                                and approved.
                            </p>
                                <p>
                                    The program comes in two modes:
                                    <ul>
                                        <li> in the parent mode, pick your baby's favorite cartoons or educational
                                            videos, bookmark them and then turn on the baby mode.
                                        </li>
                                        <li> In baby mode, you can only watch the bookmarked videos, with no access to
                                            suggested videos or search tools.
                                        </li>
                                    </ul>
                                </p>
                            </p>
                            <p>
                                Take complete control of the video that your babies or kids watch!
                                <ul>
                                    <li> Find videos good for your kids (use the keywords, e.g., Educational videos for
                                        kids age 6)
                                    </li>
                                    <li> Always keep videos ready to watch</li>
                                    <li> There are no buttons, tabs, or links in Baby Mode</li>
                                    <li> Playlist Must go On Forever</li>
                                    <li><span>Rotate the phone/tablet for the full screen</span></li>
                                </ul>
                            </p>
                            <p>
                                No Account or registration is needed!
                            </p>
                            <p>
                                BabyTube app is available for iPhone and iPad.
                            </p>
                        </div>
                        <div className="row">
                            <div className="column">
                                <img src="/bt1.png" alt="screenshot 1" width="100%"/>
                            </div>
                            <div className="column">
                                <img src="/bt2.png" alt="screenshot 2" width="100%"/>
                            </div>
                            <div className="column">
                                <img src="/bt3.png" alt="screenshot 3" width="100%"/>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <p>
                    <div className="fast_nav container">
                        <h2>Download App From The AppStore</h2>
                        <ul>
                            <li className="first">
                                <a href="https://apps.apple.com/us/app/babytube-for-parents-babies/id851093684"
                                   target="_blank"
                                   rel="noopener">
                                    <img src="/app_store.png" scale="0" alt="itunes"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </p>
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            2023 © AnswerSolutions LLC
                        </li>
                        <li>
                            <a href="#Products" role="button" onClick={() => _this.props.navigate('Products')}>Home</a>
                        </li>
                        <li>
                            <a href="#PrivacyBabyTube" role="button"
                               onClick={() => _this.props.navigate('PrivacyBabyTube')}>Privacy
                                Policy</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com">A Call Recorder App</a>
                        </li>
                        <li>
                            <a href="#support" role="button"
                               onClick={() => _this.props.navigate('Support')}>Support</a>
                        </li>
                    </ul>
                </div>
            </p>
        </div>
    }
}

export default BabyTube;