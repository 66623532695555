import React, {Component} from 'react';
import '../../Home.css';
import ReactGA from 'react-ga';

let _this;

class TimeSlotsProduct extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {
        ReactGA.pageview('timeslots');
    }

    render() {
        return <div className="card" id="shell4">
            <div id="content">
                <p>
                    <div>
                        <img style={{borderRadius: '10%'}} className="roundrect" src="/timeslots128.png" align="left"
                             hspace="10" vspace="10" alt="app icon"
                             width="128px"/>
                        <p>
                            <br/>
                            <h2>Time Slots: Your Availability, Simplified.</h2>
                            <h3>Share your availability hassle-free and avoid double bookings.</h3>
                        </p>
                    </div>
                </p>
                <br/>
                <p>
                    <b>Why you need Time Slots?</b><br/><br/>
                    Time Slots is designed for job seekers managing multiple interviews. It dynamically updates your
                    availability, so employers always see your latest open times, keeping your schedule conflict-free
                    and stress-free.
                </p>
                <p>
                    <b>Unlike other services, Time Slots is tailored specifically for job seekers!</b>
                    <ul>
                        <li> Interviewers can select the duration and number of meetings.</li>
                        <li> Works for different time zones, making scheduling easy across regions.</li>
                        <li> Offers a one-week free trial with no commitment.</li>
                        <li> Provides flexible, one-time purchase options for the exact period you are job hunting.</li>
                        <li> No subscriptions, choose between one of the plans: one, three, six, or twelve months.</li>
                        <li> Simple, straightforward, and designed to make your job search easier.</li>
                    </ul>
                </p>
                <br/>

                <div align="left">
                    <a href="https://www.producthunt.com/posts/timeslots-your-availability-simplified?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-timeslots&#0045;your&#0045;availability&#0045;simplified"
                       target="_blank"><img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=604952&theme=light"
                        alt="TimeSlots&#0058;&#0032;Your&#0032;Availability&#0044;&#0032;Simplified - Share&#0032;your&#0032;availability&#0032;hassle&#0045;free&#0032;&#0038;&#0032;avoid&#0032;double&#0032;bookings&#0046; | Product Hunt"
                        width="250" height="54"/></a>
                </div>
                <div align="center">
                    <a href="https://timeslots.pro">www.timeslots.pro</a>
                </div>
            </div>
        </div>
    }
}

export default TimeSlotsProduct;