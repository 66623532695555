import React, {Component} from 'react';
import Home from "./Home";
import Products from "./Products";
import BabyTube from "./BabyTube";
import PrivacyBabyTube from "./PrivacyBabyTube";
import AdIos from "./AdIos"
import AdAndroid from "./AdAndroid";
import ContactUs from "./ContactUs";
import BabyMonitor from "./BabyMonitor"
import PrivacyBabyMonitor from "./PrivacyBabyMonitor";
import AgreementBabyMonitor from "./AgreementBabyMonitor";

let _this;

class ASolApp extends Component {
    constructor(props, context) {
        super(props, context);

        let init_screen = 'Products';//'Home';
        // console.log('ASolApp.constructor=>'+window.location.hash);
        if (window.location.hash === '#support') {
            init_screen = 'Support';
        } else if (window.location.hash === '#adios') {
            init_screen = 'AdIos';
        } else if (window.location.hash === '#adandroid') {
            init_screen = 'AdAndroid';
        } else if (window.location.hash === '#PrivacyBabyMonitor' || window.location.pathname.endsWith('privacy-baby-monitor')) {
            init_screen = 'PrivacyBabyMonitor';
        } else if (window.location.hash === '#AgreementBabyMonitor' || window.location.pathname.endsWith('agreement-baby-monitor')) {
            init_screen = 'AgreementBabyMonitor';
        } else if (window.location.hash === '#amfree' || window.location.pathname.endsWith('amfree')) {
            init_screen = 'JobSeekerCard';
        }

        this.state = {
            target: init_screen
        };
        _this = this;

    }

    componentDidMount() {
        console.log('componentDidMount=>');
    }

    navigate(new_target) {
        console.log('navigate=>' + new_target);
        _this.setState({
            target: new_target,
        });
    };

    render() {

        let screen = null;
        if (this.state.target === 'BabyTube' || window.location.pathname.endsWith('product-baby-tube')) {
            screen = <BabyTube navigate={this.navigate}/>
        } else if (this.state.target === 'PrivacyBabyTube' || window.location.pathname.endsWith('privacy-baby-tube')) {
            screen = <PrivacyBabyTube navigate={this.navigate}/>
        } else if (this.state.target === 'BabyMonitor' || window.location.pathname.endsWith('product-baby-monitor')) {
            screen = <BabyMonitor navigate={this.navigate}/>
        } else if (this.state.target === 'PrivacyBabyMonitor' || window.location.pathname.endsWith('privacy-baby-monitor')) {
            screen = <PrivacyBabyMonitor navigate={this.navigate}/>
        } else if (this.state.target === 'AgreementBabyMonitor' || window.location.pathname.endsWith('agreement-baby-monitor')) {
            screen = <AgreementBabyMonitor navigate={this.navigate}/>
        } else if (this.state.target === 'AdIos') {
            screen = <AdIos navigate={this.navigate}/>
        } else if (this.state.target === 'AdAndroid') {
            screen = <AdAndroid navigate={this.navigate}/>
        } else if (this.state.target === 'Support') {
            screen = <ContactUs navigate={this.navigate}/>
        }  else {
            screen = <Products navigate={this.navigate}/>
        }

        return (
            <div className="App">
                {screen}
            </div>
        );
    }
}

export default ASolApp;
