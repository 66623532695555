import React, {Component} from 'react';
import './Home.css';
let _this;

class ContactUs extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <br/>
            <p className="normal">
                <h1>Product Support And Feedback
                </h1>
            </p>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Have an issue with the app or service?</h2>
                    <p>
                        When submitting a complaint, please provide a brief description of the nature of your complaint
                        and the specific services to which your complaint relates.
                    </p>
                    <p>
                        Send an Email to: <a href="mailto:support@answersolutions.net?subject=A Call Recorder App">Support</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Do you want to give a feedback or suggestion?</h2>
                    <p>
                        Send an Email to: <a href="mailto:feedback@answersolutions.net?subject=A Call Recorder App">Feedback</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Do you have a business offer?</h2>
                    <p>
                        Send an Email to: <a href="mailto:sales@answersolutions.net?subject=A Call Recorder App">Sales</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            2023 © AnswerSolutions LLC
                        </li>
                        <li>
                            <a href="#Products" role="button" onClick={() => _this.props.navigate('Products')}>Home</a>
                        </li>
                        <li>
                            <a href="#babyTube" role="button"
                               onClick={() => _this.props.navigate('BabyTube')}>BabyTube</a>
                        </li>
                        <li>
                            <a href="#BabyMonitor" role="button"
                               onClick={() => _this.props.navigate('BabyMonitor')}>Baby Monitor</a>
                        </li>
                        <li>
                            <a href="https://photodj.me">PhotoDj</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com">StudyCards</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com">A Call Recorder</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com">WiseTalk</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com">Up Write AI</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default ContactUs;