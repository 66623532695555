import React, {Component} from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from 'material-ui/Paper';
import {Layout, Flex, Fixed} from 'react-layout-pane';
import './App.css';
import ReactGA from 'react-ga';

let _this;

class Home extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            appStatus: 'wait',
            imageURL: null,
        };
        _this = this
    }

    componentDidMount() {
        ReactGA.pageview('home');
        //1. get session id with qr code
        //2. on handshake send session id to backend by AUTH
        //3. on backend keep dictionary of session id and sockets (on disconnect remove)
        //4. send personal command from backend to frontend
    }

    onCloseButton() {
        _this.setState({
            appStatus: 'wait',
            imageURL: null
        });
    }

    render() {
        return (
            <div className="App">
                <MuiThemeProvider>
                    <Layout type="column">
                        <Fixed className="header">
                            <Paper className="qr_paper_top" zDepth={1}>
                                <div id="container">
                                    <div id="left">
                                        <h3>AnswerSolutions LLC</h3>
                                    </div>
                                    <div id="middle">
                                    </div>
                                    <div id="right" className="top_menu_container">
                                        <div>
                                            <a href="#products" role="button"
                                               onClick={() => _this.props.navigate('Products')}><h3
                                                className='top_menu'>Products</h3>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="mailto:support@answersolutions.net" role="button"><h3
                                                className='top_menu'>Support</h3>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </Fixed>
                        <Flex>
                            <div className="container_content">
                                <br/>
                                <div className="card" id="shell2">
                                    <div id="content">
                                        <p>
                                            <div>
                                                <p><h2>Answer@Solutions is under development</h2></p>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <br/>
                                <div className="card" id="shell1">
                                    <div id="content">
                                        <p>
                                            We make great products that make people's life a bit better.
                                        </p>
                                    </div>
                                </div>
                                <br/>
                                <div className="card" id="shell1">
                                    <div id="content">
                                        <p>
                                            Our apps and services will help you find the answers to some of your
                                            questions and solutions to some of your problems.
                                        </p>

                                    </div>
                                </div>
                                <br/>
                            </div>
                        </Flex>
                        <div className="fast_nav container">
                            <ul>
                                <li className="first">
                                    2023 © AnswerSolutions LLC
                                </li>
                                <li>
                                    <a href="https://photodj.me">PhotoDj App</a>
                                </li>
                                <li>
                                    <a href="https://studycardsapp.com">StudyCards App</a>
                                </li>
                                <li>
                                    <a href="mailto:support@answersolutions.net">Contact us</a>
                                </li>
                            </ul>
                        </div>
                    </Layout>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default Home;
